import { CHARGIFY_COMMERCE_CACHE_LIFETIME_IN_MINUTES } from '../constants';

export interface CacheEntry {
  data: any;
  expiry: number;
}

/**
 * add minutes
 */
export const addMinutes = (date: Date, minutes: number): number => {
  return date.getTime() + minutes * 60000;
};

/**
 * set cache item
 */
export const setCacheItem = (
  key: string,
  value: string,
  expiryInMinutes = CHARGIFY_COMMERCE_CACHE_LIFETIME_IN_MINUTES
): void => {
  const now = new Date();

  const item: CacheEntry = {
    data: value,
    expiry: addMinutes(now, expiryInMinutes),
  };

  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * get cache item
 */
export const getCacheItem = (key: string): CacheEntry | null => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const now = new Date();
  const item: CacheEntry = JSON.parse(itemStr);

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);

    return null;
  }

  return item;
};
