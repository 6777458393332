import axios from 'axios';

import { SelectorsGroup, selectors } from './selectors/index';
import { ConfigResponseData, getConfig, getCardsShouldHide } from './api';
import { logger } from './utils';
import {
  DigitalItem,
  LineItemMap,
  LineItemOption,
  PhysicalItem,
} from './cart-types';

/**
 * interfaces
 */
interface Config {
  storeHash: string;
  theme: string;
  selectors: SelectorsGroup;
  pageType: string;
}

interface State {
  appliedListeners: boolean;
  chargifyCommerceConfig: ConfigResponseData;
  cartItems: LineItemMap;
}

/**
 * default state objects
 */
const cartItemsDefaultState = {
  physicalItems: [],
  digitalItems: [],
  customItems: [],
  giftCertificates: [],
};

const chargifyCommerceConfigDefaultState = {
  access: false,
  config: {
    enabled: false,
    autoshipOptionSetLabels: [],
    allowCouponWithSubscriptionItem: true
  },
};

/**
 * primary class
 */
class DittopayStorefrontScript {
  config: Config;
  state: State;
  selectors: SelectorsGroup;

  constructor(config: Partial<Config>) {
    logger('Storefront Script');

    // STATIC: things that are set once the class is initialized
    this.config = {
      storeHash: config.storeHash || '',
      pageType: config.pageType || '',
      theme: config.theme || 'cornerstone',
      selectors: config.selectors || ({} as SelectorsGroup),
    };

    // DYNAMIC: things that change based on logic or events
    this.state = {
      appliedListeners: false,
      cartItems: cartItemsDefaultState,
      chargifyCommerceConfig: chargifyCommerceConfigDefaultState,
    };

    // get selectors based on theme + any overrides passed into the config obj
    this.selectors = this.getSelectors();

    // kick off the work
    this.init();
  }

  /**
   * get selectors - since it takes some logic to get the right selectors, use this method to always correctly get them
   */
  getSelectors(): SelectorsGroup {
    // get base theme selectors (if we don't support provided theme string, fallback to defaults aka cornerstone)
    const themeSelectors: SelectorsGroup =
      selectors[this.config.theme] || selectors['cornerstone'];
    // merge in any overrides that were passed in as a custom config
    return {
      hasSubscriptions: [
        ...themeSelectors.hasSubscriptions,
        ...(this.config.selectors.hasSubscriptions || []),
      ],
      hasAutoshipItemsInCart: [
        ...themeSelectors.hasAutoshipItemsInCart,
        ...(this.config.selectors.hasAutoshipItemsInCart || []),
      ],
    };
  }

  /**
   * hide elements by using our theme based selectors
   */
  hideElements(selectors: string[]): void {
    selectors.forEach((selector) => {
      const elements = document.querySelectorAll<HTMLElement>(selector);
      elements.forEach((element) => {
        element.style.display = 'none';
      });
    });
  }

  /**
   * method to update our state
   */
  setState(updates: Partial<State>): void {
    const newState: State = {
      ...this.state,
      ...updates,
    };

    this.state = newState;
  }

  /**
   * get chargify commerce config
   */
  async getChargifyCommerceConfig(): Promise<void> {
    const { data, isError } = await getConfig(this.config.storeHash);

    if (!data || isError) {
      return this.setState({
        chargifyCommerceConfig: chargifyCommerceConfigDefaultState,
      });
    }

    this.setState({ chargifyCommerceConfig: data });
  }

  /**
   * get cart items state
   */
  async updateCartItemsState(): Promise<void> {
    logger(`-- fetching BigCommerce cart`);

    try {
      const { data } = await axios.get(
        '/api/storefront/carts?include=lineItems.digitalItems.options%2ClineItems.physicalItems.options'
      );
      const cartItems = data.length ? data[0].lineItems : cartItemsDefaultState;

      this.setState({ cartItems });
    } catch (error: any) {
      logger(`unable to fetch BigCommerce cart: ${error.message}`);

      this.setState({ cartItems: cartItemsDefaultState });
    }
  }

  /**
   * check cart items to see if there are subscription items in the cart
   */
  haveAutoshipItemsInCart(): boolean {
    // helper fn for checking options for dittopay autoshipOptionSetLabels
    const { autoshipOptionSetLabels } =
      this.state.chargifyCommerceConfig.config;
    const hasAutoshipOption = (
      hasAutoshipOption: boolean,
      option: LineItemOption
    ): boolean => {
      if (autoshipOptionSetLabels.includes(option.name)) {
        hasAutoshipOption = true;
      }
      return hasAutoshipOption;
    };

    // helper fn for checking cart items for dittopay autoshipOptionSetLabels
    const hasAutoshipItem = (
      hasSub: boolean,
      item: PhysicalItem | DigitalItem
    ): boolean => {
      const hasOptions = item.options && item.options.length;
      if (hasOptions) {
        const containsSubOption = item?.options?.reduce(
          hasAutoshipOption,
          false
        );
        if (containsSubOption) {
          hasSub = true;
        }
      }
      return hasSub;
    };

    // grab our different item types and check them
    const { digitalItems, physicalItems } = this.state.cartItems;
    const haveDigitalSubItem = digitalItems.reduce(hasAutoshipItem, false);
    const havePhysicalSubItem = physicalItems.reduce(hasAutoshipItem, false);

    // return result (do any have a sub item?)
    return haveDigitalSubItem || havePhysicalSubItem;
  }

  /**
   * is dittopay paid for and turned on?
   */
  hasAccessAndIsEnabled(): boolean {
    return (
      this.state.chargifyCommerceConfig.access &&
      this.state.chargifyCommerceConfig.config.enabled
    );
  }

  /**
   * check autoship items in cart and handle things accordingly
   * - right now, always assume customer has subscriptions, later we'll actually update the state dynamically
   */


   findNearestElementWithClass(element: HTMLElement, className: string): HTMLElement | null {
    return element.closest(`.${className}`);
  }

  async handleHideSavedCardActions() {
    const isAccountPage = window.location.pathname === '/account.php';

    if (this.hasAccessAndIsEnabled() && isAccountPage) {
      logger(`-- hiding Saved Card action buttons`);

      const { data, isError } = await getCardsShouldHide();

      const cards_should_hide = data?.cards_should_hide;
      if (cards_should_hide && cards_should_hide?.length && !isError) {
        this.selectors.hasSubscriptions.forEach((selector) => {
          const elements = document.querySelectorAll<HTMLElement>(selector);
          elements.forEach((element) => {
            const paymentMethodBoxElement = this.findNearestElementWithClass(element, 'paymentMethod')

            if (paymentMethodBoxElement) {
              const cardContent = paymentMethodBoxElement.getElementsByClassName("methodHeader-brand")?.[0]?.textContent as string
              const cardExp = paymentMethodBoxElement.getElementsByClassName("methodHeader-expiry")?.[0]?.textContent as string
              const match = cardContent.match(/\d+/);
              const last4 = match ? match[0] : null;
              const [cardExpMonth, cardExpYear] = cardExp.split('/');

              //convert 2024 -> 24
              const cardExpYearLast2 = cardExpYear.substr(2);

              const isMatchCard = cards_should_hide.filter(card => {
                return card.last_four === last4 &&
                    (card.exp_month === null || parseInt(card.exp_month, 10) == parseInt(cardExpMonth, 10)) &&
                    (card.exp_year === null || parseInt(card.exp_year, 10) == parseInt(cardExpYearLast2, 10));
            }).length;

              if (isMatchCard) {
                element.style.display = 'none';
              }
            }
          });
        });
      }
    }
  }

  /**
   * check autoship items in cart and handle things accordingly
   */
  async handleHideExternalCheckoutButtons(): Promise<void> {
    if (this.hasAccessAndIsEnabled()) {
      // 1. get updated cart data
      await this.updateCartItemsState();

      // 2. if have autoship items in cart, hide those elements
      if (this.haveAutoshipItemsInCart()) {
        logger(`-- hiding external checkout buttons`);

        this.hideElements(this.selectors.hasAutoshipItemsInCart);

        setTimeout(() => {
          this.hideElements(this.selectors.hasAutoshipItemsInCart);
        }, 1250);

        setTimeout(() => {
          this.hideElements(this.selectors.hasAutoshipItemsInCart);
        }, 2500);
      }
    }
  }

  /**
   * if autoship items in cart, disable coupons
   */
   async handleDisableCoupons(): Promise<void> {
    if (this.hasAccessAndIsEnabled()) {
      // 1. get updated cart data
      await this.updateCartItemsState();

      // 2. if have autoship items in cart, hide those elements
      if (this.haveAutoshipItemsInCart() && !this.state.chargifyCommerceConfig.config.allowCouponWithSubscriptionItem) {
        logger(`-- disabling coupons`);

        this.hideCouponCodeElement();

      }
    }
  }

  hideCouponCodeElement() {
    const text = 'Coupon Code';

    const couponUl = document.getElementsByClassName("cart-totals")[0];

    const elements = Array.from(couponUl.querySelectorAll('li'));

    const matchingEl = elements.find(el => {
      return (el.textContent || '').includes(text);
    });

    if (matchingEl) {
      matchingEl.style.display = "none";
    }

  }

  /**
   * listen for events
   */
  applyListeners(): void {
    if (this.state.appliedListeners) {
      return;
    }

    this.setState({ appliedListeners: true });

    if (!window.stencilUtils || !window.stencilUtils.hooks) {
      return;
    }

    window.stencilUtils.hooks.on('cart-item-add-remote', async () => {
      logger('Item Added to Cart');
      await this.handleHideExternalCheckoutButtons();
    });

    window.stencilUtils.hooks.on('cart-item-update-remote', async () => {
      logger('Cart Item Updated');
      await this.handleHideExternalCheckoutButtons();
    });

    window.stencilUtils.hooks.on('cart-item-remove-remote', async () => {
      logger('Item Removed From Cart');
      await this.handleHideExternalCheckoutButtons();
    });
  }

  /**
   * initialize stuffz
   */
  async init(): Promise<void> {
    // 1. get dittopay config (is dittopay paid for and enabled?)
    await this.getChargifyCommerceConfig();
    // 2. handle customer having existing subscriptions
    this.handleHideSavedCardActions();
    // 3. handle customer having autoship items in the cart if on cart page
    if (window.location.pathname === '/cart.php') {
      await this.handleHideExternalCheckoutButtons();
    }
    // 4. disable coupons if autoship items in the cart and on cart page
    if (window.location.pathname === '/cart.php') {
      await this.handleDisableCoupons();
    }

    // 5. aplly listeners
    this.applyListeners();
  }
}

/**
 * expose our class to the window object
 */
declare global {
  interface Window {
    DittopayStorefrontScript: {};
    stencilUtils: {
      hooks: {
        on(
          eventName: string,
          callback: (event: Event, target: HTMLElement) => void
        ): void;
      };
    };
  }
}

window.DittopayStorefrontScript = DittopayStorefrontScript;

export default DittopayStorefrontScript;
