import axios from 'axios';
import { getToken } from './get-token';

export interface GetCardShouldHideResponse {
  data: {cards_should_hide:  CardShouldHideData[]} | null;
  message: string;
  isError: boolean;
}

export type GetCardShouldHide = () => Promise<GetCardShouldHideResponse>;

export interface CardShouldHideData {
  card_type: string;
  exp_month: string;
  exp_year: string;
  last_four: string;
}

export interface CardsShouldHideConfigResponse {
  meta: {
    status: 'success' | 'error';
    message?: string;
  };
  data: {
    cards_should_hide:  CardShouldHideData[];
  };
}

/**
 * Function
 */
export const getCardsShouldHide: GetCardShouldHide = async () => {
  try {
    const token = await getToken();
    const { data, meta }: CardsShouldHideConfigResponse = await axios.get(
      `${process.env.API_URL}/cards_should_hide?token=${token}`
    ).then((res) => res.data);

    if (!data) {
      return {
        data: null,
        message: `Error: Something went wrong`,
        isError: true,
      };
    }
    // handle response
    if (meta && meta.status === 'error') {
      return {
        data: null,
        message: `Error: ${meta.message || ''}`,
        isError: true,
      };
    }


    return {
      data: data,
      message: meta.message || 'Success',
      isError: false,
    };
  } catch (error: any) {
    const errMessage = error?.response?.data.meta.message;

    return {
      data: null,
      message: `Error: ${errMessage || error.message}`,
      isError: true,
    };
  }
};

