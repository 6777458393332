import { SelectorsGroup } from './index';

const cornerstone: SelectorsGroup = {
  hasSubscriptions: [
    'form[action*="action=delete_payment_method"]', // account payment method edit and delete card buttons
  ],
  hasAutoshipItemsInCart: [
    '.cart-additionalCheckoutButtons', // cart page checkout buttons
    '.previewCartCheckout-additionalCheckoutButtons', // post add to cart modal checkout buttons
    '.previewCart-additionalCheckoutButtons', // cart preview checkout buttons
  ],
};

export default cornerstone;
