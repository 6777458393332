import axios from 'axios';

import { getCacheItem, logger, setCacheItem } from '../utils';

/**
 * API interfaces
 */
export interface ActivePaymentMethod {
  checkout_id: string;
  checkout_provider_id: string;
  id: string;
  name: string;
  profile_source: string;
}

export interface AutoshipShippingOverrides {
  optionName: string;
  shippingValue: string;
  valueName: string;
}

export interface ConfigData {
  access: boolean;
  config: {
    activePaymentMethodIds: string[];
    activePaymentMethods: Record<string, ActivePaymentMethod>;
    autoshipItemInCartMessage: string;
    autoshipOptionSetLabels: string[];
    membershipAutoshipOptionSetLabels: string[];
    autoshipShippingOverrides: AutoshipShippingOverrides[];
    chargify: {
      hostname: string;
      publicKey: string;
    };
    enabled: boolean;
    orderConfirmationSubscriptionPaymentMessage: string;
    allowCouponWithSubscriptionItem: boolean;
  };
}

export interface ConfigResponse {
  meta: {
    status: 'success' | 'error';
    message?: string;
  };
  data: {
    dittopay: ConfigData;
  };
}

/**
 * Function interfaces
 */
export interface ConfigResponseData {
  access: ConfigData['access'];
  config: {
    enabled: ConfigData['config']['enabled'];
    autoshipOptionSetLabels: ConfigData['config']['autoshipOptionSetLabels'];
    allowCouponWithSubscriptionItem: ConfigData['config']['allowCouponWithSubscriptionItem'];
  };
}

export interface GetConfigResponse {
  data: ConfigResponseData | null;
  message: string;
  isError: boolean;
}

export type GetConfig = (storeHash: string) => Promise<GetConfigResponse>;

/**
 * Function
 */
export const getConfig: GetConfig = async (storeHash) => {
  // try to pull from cache first
  const cachedResponse = getCacheItem(`chargify-commerce-config-${storeHash}`);
  if (cachedResponse) {
    logger(
      `-- using cached Chargify Commerce Configuration (expires ${new Date(
        cachedResponse.expiry
      ).toLocaleTimeString()})`
    );

    return {
      data: JSON.parse(cachedResponse.data),
      message: 'Success',
      isError: false,
    };
  }

  logger('-- fetching Chargify Commerce Configuration');

  try {
    const { data, meta }: ConfigResponse = await axios.get(
      `${process.env.API_URL}/config?store_hash=${storeHash}`
    ).then((res) => res.data);

    if (!data) {
      return {
        data: null,
        message: `Error: Something went wrong`,
        isError: true,
      };
    }
    // handle response
    if (meta && meta.status === 'error') {
      return {
        data: null,
        message: `Error: ${meta.message || ''}`,
        isError: true,
      };
    }

    const responseData: ConfigResponseData = {
      access: data.dittopay.access,
      config: {
        enabled: data.dittopay.config.enabled,
        autoshipOptionSetLabels: data.dittopay.config.autoshipOptionSetLabels,
        allowCouponWithSubscriptionItem:
          data.dittopay.config.allowCouponWithSubscriptionItem,
      },
    };

    setCacheItem(
      `chargify-commerce-config-${storeHash}`,
      JSON.stringify(responseData)
    );

    return {
      data: responseData,
      message: meta.message || 'Success',
      isError: false,
    };
  } catch (error: any) {
    logger(`unable to get Chargify Commerce Configuration: ${error.message}`);
    const errMessage = error?.response?.data.meta.message;

    return {
      data: null,
      message: `Error: ${errMessage || error.message}`,
      isError: true,
    };
  }
};
