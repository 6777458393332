import axios from 'axios';
import jwt_decode from 'jwt-decode';

let globalTokenPromise: Promise<string> | null = null;
/**
 * get token
 */
export async function getToken() {
    if (globalTokenPromise) {
        const result = await globalTokenPromise;

        if (tokenIsValid(result)) {
            return result;
        }
    }

    const result = await (globalTokenPromise = getNewToken());

    return result;
}


interface CustomerToken {
    exp: number;
}

/**
 * validate token
 */
const tokenIsValid = (token: string) => {
    if (!token) {
        return false;
    }
    const decoded = jwt_decode<CustomerToken>(token);
    return decoded.exp >= Math.floor(Date.now() / 1000) - 10;
};


/**
 * get jwt token
 * - this method is only called when no token is in global state or the token in global lstate is invalid
 */
export const getNewToken = async (): Promise<string> => {
    // setup variables
    const clientId = process.env.REACT_APP_BIGCOMMERCE_CLIENT_ID || '';
    const url = `/customer/current.jwt?app_client_id=${clientId}`;

    // fetch token
    try {
        const data = await axios.get(url).then((res) => res.data);
        return data.token ? data.token : String(data);
    } catch (error) {
        console.error(error);
        throw error;
    }
};
