import cornerstone from './cornerstone';

/**
 * interfaces
 */
export interface SelectorsGroup {
  hasSubscriptions: string[];
  hasAutoshipItemsInCart: string[];
}

export interface Selectors {
  [key: string]: SelectorsGroup;
}

/**
 * our theme selectors
 */
export const selectors: Selectors = {
  cornerstone,
};
